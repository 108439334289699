import React from 'react'
import Layout from '../components/layout-en'
import Head from '../components/head'
import { Link } from 'gatsby'
import '../styles/privacy-policy.css'

class PrivacyPolicy extends React.Component {

    constructor(props){
      super(props);
      this.state = {
      }
    }

    render(){

        return(

            <Layout>
                <Head title = "Privacy and Policy"/>
                <div id = "pp-main-container">
                    
                    <div id = "pp-title">

                        <div id = "pp-title-container">
                        <div id = "pp-title-primary">Privacy Policy</div>
                        </div>

                    </div>

                    <div id = "pp-menu">
                        <div class = "pp-menu-item-active">Privacy Policy</div>
                        <Link to = "/terms-of-use" id = "terms-of-use-link">
                            <div class = "pp-menu-item">Terms of Use</div>
                        </Link>
                    </div>

                    <div id = "pp-body">

                        <div class = "pp-content-subtitle2">Effective Date: February 19, 2019</div>

                        <div class = "pp-content-section">
                            <div class = "pp-content">
                                Neura Cipta Nusantara LLC ("us", "we", or "our") operates the Dr. Tania mobile application (hereinafter referred to as the "Service").
                            </div>
                            <div class = "pp-content">
                                This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and 
                                the choices you have associated with that data. 
                                This Privacy Policy is managed by Privacy Policy Generator.
                            </div>
                            <div class = "pp-content">
                                We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. 
                                Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
                            </div>
                        </div>

                        <div class = "pp-content-section">
                            <div class = "pp-content-title">Definitions</div>
                            <div class = "pp-content-subtitle2">Service</div>
                            <div class = "pp-content">
                                Service is the Dr. Tania mobile application operated by Neura Cipta Nusantara LLC.
                            </div>
                            <div class = "pp-content-subtitle2">Personal Data</div>
                            <div class = "pp-content">
                                Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
                            </div>
                            <div class = "pp-content-subtitle2">Usage Data</div>
                            <div class = "pp-content">
                                Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                            </div>
                            <div class = "pp-content-subtitle2">Cookies</div>
                            <div class = "pp-content">
                                Cookies are small files stored on your device (computer or mobile device).
                            </div>
                        </div>

                        <div class = "pp-content-section">
                            <div class = "pp-content-title">Information Collection and Use</div>
                            <div class = "pp-content">
                                We collect several different types of information for various purposes to provide and improve our Service to you.
                            </div>
                            <div class = "pp-content-subtitle1">Types of Data Collected</div>
                            <div class = "pp-content-subtitle2">Personal Data</div>
                            <div class = "pp-content">
                                While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:     
                            </div>
                            <div class = "pp-content">
                                <li>Email address</li>
                                <li>First name and last name</li>
                                <li>Phone number</li>
                                <li>Address, State, Province, ZIP/Postal code, City</li>
                                <li>Cookies and Usage Data</li>
                            </div>
                            <div class = "pp-content-subtitle2">Usage Data</div>
                            <div class = "pp-content">
                                When you access the Service with a mobile device, we may collect certain information automatically, including, but not limited to, 
                                the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, 
                                the type of mobile Internet browser you use, unique device identifiers and other diagnostic data ("Usage Data").
                            </div>
                            <div class = "pp-content-subtitle2">Tracking and Cookies Data</div>
                            <div class = "pp-content">
                                We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information. 
                                Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser 
                                from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and 
                                track information and to improve and analyse our Service. You can instruct your browser to refuse all cookies or to indicate when a 
                                cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service. Examples of 
                                Cookies we use:
                            </div>
                            <div class = "pp-content">
                                <li><b>Session Cookies. </b>We use Session Cookies to operate our Service.</li>
                                <li><b>Preference Cookies. </b>We use Preference Cookies to remember your preferences and various settings.</li>
                                <li><b>Security Cookies. </b>We use Security Cookies for security purposes.</li>
                            </div>
                        </div>

                        <div class = "pp-content-section">
                            <div class = "pp-content-title">Use of Data</div>
                            <div class = "pp-content">
                                Neura Cipta Nusantara LLC uses the collected data for various purposes:
                            </div>
                            <div class = "pp-content">
                                <li>To provide and maintain our Service</li>
                                <li>To notify you about changes to our Service</li>
                                <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                                <li>To provide customer support</li>
                                <li>To gather analysis or valuable information so that we can improve our Service</li>
                                <li>To monitor the usage of our Service</li>
                                <li>To detect, prevent and address technical issues</li>
                            </div>
                        </div>

                        <div class = "pp-content-section">
                            <div class = "pp-content-title">Transfer of Data</div>
                            <div class = "pp-content">
                                Your information, including Personal Data, may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
                            </div>
                            <div class = "pp-content">
                            If you are located outside and choose to provide information to us, please note that we transfer the data, including Personal Data, to and process it there.
                            </div>
                            <div class = "pp-content">
                                Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                            </div>
                            <div class = "pp-content">
                            Neura Cipta Nusantara LLC will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.
                            </div>
                        </div>

                        <div class = "pp-content-section">
                            <div class = "pp-content-title">Disclosure of Data</div>
                            <div class = "pp-content-subtitle1">Legal Requirements</div>
                            <div class = "pp-content">
                                Neura Cipta Nusantara LLC may disclose your Personal Data in the good faith belief that such action is necessary to:
                            </div>
                            <div class = "pp-content">
                                <li>To comply with a legal obligation</li>
                                <li>To protect and defend the rights or property of Neura Cipta Nusantara LLC</li>
                                <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                                <li>To protect the personal safety of users of the Service or the public</li>
                                <li>To protect against legal liability</li>
                            </div>
                        </div>

                        <div class = "pp-content-section">
                            <div class = "pp-content-title">Security of Data</div>
                            <div class = "pp-content">
                                The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                            </div>
                        </div>

                        <div class = "pp-content-section">
                            <div class = "pp-content-title">Service Providers</div>
                            <div class = "pp-content">
                                We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services or assist us in analysing how our Service is used. These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                            </div>
                            <div class = "pp-content-subtitle1">Analytics</div>
                            <div class = "pp-content">
                                We may use third-party Service Providers to monitor and analyse the use of our Service.
                            </div>
                            <div class = "pp-content-subtitle2">Google Analytics</div>
                            <div class = "pp-content">
                                Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.
                            </div>
                            <div class = "pp-content">
                                You may opt-out of certain Google Analytics features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: https://policies.google.com/privacy
                            </div>
                            <div class = "pp-content">
                                For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy
                            </div>
                            <div class = "pp-content-subtitle2">Firebase</div>
                            <div class = "pp-content">
                                Firebase is an analytics service provided by Google Inc.
                            </div>
                            <div class = "pp-content">
                                You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: https://policies.google.com/privacy
                            </div>
                            <div class = "pp-content">
                                We also encourage you to review the Google's policy for safeguarding your data: https://support.google.com/analytics/answer/6004245
                            </div>
                            <div class = "pp-content">
                                For more information on what type of information Firebase collects, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy
                            </div>
                        </div>

                        <div class = "pp-content-section">
                            <div class = "pp-content-title">Link to Other Sites</div>
                            <div class = "pp-content">
                                Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
                            </div>
                        </div>

                        <div class = "pp-content-section">
                            <div class = "pp-content-title">Children's Privacy</div>
                            <div class = "pp-content">
                                Our Service does not address anyone under the age of 18 ("Children").
                            </div>
                            <div class = "pp-content">
                                We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
                            </div>
                        </div>

                        <div class = "pp-content-section">
                            <div class = "pp-content-title">Changes to This Privacy Policy</div>
                            <div class = "pp-content">
                                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.
                            </div>
                            <div class = "pp-content">
                                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                            </div>
                        </div>

                        <div class = "pp-content-section">
                            <div class = "pp-content-title">Contact Us</div>
                            <div class = "pp-content">
                                If you have any questions about this Privacy Policy, please contact us:
                            </div>
                            <div class = "pp-content">
                                <li>By email: hi@neurafarm.com</li>
                            </div>
                        </div>

                    </div>

                </div>
            </Layout>
        
        );
    }
}

export default PrivacyPolicy;